<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="admin-dashboard-analytics">
    <div class="vx-row" v-if="headOfFleet">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Overview')}}</h2>
      </div>
    </div>
    <template>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <h2>{{$t('Today’s Last-mile')}}</h2>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="TruckIcon"
            :statistic="dashboardStats.today_attempt_delivery_orders_count + '/' + dashboardStats.today_success_delivery_orders_count + '/' + dashboardStats.today_delivery_orders_count"
            :statisticTitle="$t('Attempted / Deliveried / Total')"
            color="success"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="TruckIcon"
            class="rotate"
            :statistic="dashboardStats.today_success_ca_picked_up_orders_count + '/' + dashboardStats.today_ca_pickups_orders_count"
            :statisticTitle="$t('Customer Pickups')"
            color="success"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="RepeatIcon"
            :statistic="dashboardStats.today_success_exchange_orders_count + '/' + dashboardStats.today_exchange_orders_count"
            :statisticTitle="$t('Exchanges')"
            color="danger"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="DollarSignIcon"
            :statistic="dashboardStats.today_success_cash_collection_requests_count + '/' + dashboardStats.today_cash_collection_requests_count"
            :statisticTitle="$t('Cash Collections')"
            color="success"
          ></statistics-card-line>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <h2>{{$t('Today’s First-mile')}}</h2>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="PackageIcon"
            :statistic="dashboardStats.today_reschedule_ma_picked_up_orders_count + '/' + dashboardStats.today_success_ma_picked_up_orders_count + '/' + dashboardStats.today_ma_pickups_orders_count"
            :statisticTitle="$t('Attempted / Pickedup / Total')"
            color="success"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="BoxIcon"
            :statistic="dashboardStats.today_success_supplies_requests_count + '/' + dashboardStats.today_supplies_requests_count"
            :statisticTitle="$t('Supplies Requests')"
            color="success"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="PackageIcon"
            :statistic="dashboardStats.today_success_return_orders_count + '/' + dashboardStats.today_return_orders_count"
            :statisticTitle="$t('Merchant Returns')"
            color="danger"
          ></statistics-card-line>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <h2>{{$t('Today’s Financials')}}</h2>
        </div>
      </div>
      <div class="vx-row">
        <div  class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="DollarSignIcon"
            :statistic="dashboardStats.in_house_cash ? dashboardStats.in_house_cash.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''"
            :statisticTitle="$t('In-House Cash EGP')"
            color="success"
          ></statistics-card-line>
        </div>
        <div  class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="DollarSignIcon"
            :statistic="dashboardStats.success_cod + '/' + dashboardStats.expected_cod"
            :statisticTitle="$t('COD EGP')"
            color="success"
          ></statistics-card-line>
        </div>
        <div  class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="DollarSignIcon"
            :statistic="dashboardStats.success_refunds + '/' + dashboardStats.expected_refunds"
            :statisticTitle="$t('Refunds EGP')"
            color="danger"
          ></statistics-card-line>
        </div>
        <div  class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="DollarSignIcon"
            :statistic="dashboardStats.today_success_cash_collection_requests_amount + '/' + dashboardStats.today_expected_cash_collection_requests_amount"
            :statisticTitle="$t('Cash Collections EGP')"
            color="success"
          ></statistics-card-line>
        </div>    
      </div>
       <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <h2>{{$t('Warehouse Stats')}}</h2>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="PackageIcon"
            :statistic="dashboardStats.in_house_orders"
            :statisticTitle="$t('In-House Orders')"
            color="success"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="PackageIcon"
            :statistic="dashboardStats.incoming_house_orders"
            :statisticTitle="$t('In-Coming Orders')"
            color="warning"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="PackageIcon"
            :statistic="dashboardStats.today_delivery_orders_return_to_origin_orders_count"
            :statisticTitle="$t('In-House RTOs')"
            color="danger"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="ArchiveIcon"
            :statistic="dashboardStats.used_shelves + '/' + dashboardStats.all_shelves"
            :statisticTitle="$t('Slots')"
            color="success"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="ShoppingBagIcon"
            :statistic="dashboardStats.ingoing_master_bags"
            :statisticTitle="$t('Incoming Master Bags')"
            color="success"
          ></statistics-card-line>
        </div>
        <div class="vx-col w-full lg:w-1/4 sm:w-1/2 mb-base">
          <statistics-card-line
            hideChart
            :dataLoading="dashboardStatsLoading"
            icon="ShoppingBagIcon"
            :statistic="dashboardStats.outgoing_master_bags"
            :statisticTitle="$t('Outgoing Master Bags')"
            color="danger"
          ></statistics-card-line>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import StatisticsCardLine from '../../components/statistics-cards/StatisticsCardLine.vue'
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'

export default {
  data () {
    return {
      dashboardStats: {
        today_task_sheets_count: 0,
        today_pickups_orders_count: 0,
        today_attempt_delivery_orders_count: 0,
        today_reschedule_ma_picked_up_orders_count: 0,
        today_success_delivery_orders_count: 0,
        today_delivery_orders_count: 0,
        today_success_ca_picked_up_orders_count: 0,
        today_ca_pickups_orders_count: 0,
        today_success_exchange_orders_count: 0,
        today_exchange_orders_count: 0,
        today_success_cash_collection_requests_count: 0,
        today_cash_collection_requests_count: 0,
        today_success_ma_picked_up_orders_count: 0,
        today_ma_pickups_orders_count: 0,
        today_success_supplies_requests_count: 0,
        today_supplies_requests_count: 0,
        today_success_return_orders_count: 0,
        today_return_orders_count: 0,
        in_house_cash: 0,
        success_cod: 0,
        expected_cod: 0,
        success_refunds: 0,
        expected_refunds: 0,
        today_success_cash_collection_requests_amount: 0,
        today_expected_cash_collection_requests_amount: 0,
        in_house_orders: 0,
        incoming_house_orders: 0,
        today_delivery_orders_return_to_origin_orders_count: 0,
        used_shelves: 0,
        all_shelves: 0,
        ingoing_master_bags: 0,
        outgoing_master_bags: 0
      },
      dashboardStatsLoading: false,
      headOfFleet: {},
      warehousesDic: {}
    }
  },
  methods: {
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.id] = warehouse.code
      })
      this.loadHeadOfFleet()
    },
    loadHeadOfFleet () {
      sendRequest(false, false, this, `api/v1/users/heads-of-fleet/${this.$store.state.AppActiveUser.uid}/`, 'get', null, true, 
        (response) => {
          this.headOfFleet = response.data
          this.loadDashboard()
        }
      )
    },
    loadDashboard () {
      this.dashboardStatsLoading = true
      sendRequest(true, false, this, `api/v1/analytics/team-leader/dashboard/?warehouse_code=${this.headOfFleet.primary_warehouse ? this.warehousesDic[this.headOfFleet.primary_warehouse] : ''}`, 'get', null, true, 
        (response) => {
          this.dashboardStats = response.data
          this.dashboardStatsLoading = false
        }
      )
    }
  },
  components: {
    StatisticsCardLine
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#admin-dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
